/**
 * Data store for the resourceCreate and resourceEdit pages.
 */
import { parseISO } from 'date-fns';
import {
  applySnapshot,
  types,
  flow,
  getEnv,
  addMiddleware,
  getRoot,
  getSnapshot,
  Instance,
} from 'mobx-state-tree';

import createListModel from 'src/shared/stores/createListModel';
import { Patient } from 'src/shared/stores/resource';
import Auth from 'src/stores/auth';
import Calendar from 'src/stores/calendar';
import Events from 'src/stores/events';
import { Event, EventInstance } from 'src/stores/models/event';
import { MESSAGE_CHART_DOCUMENT_TO_PATIENT } from 'src/stores/mutations/files';
import Providers from 'src/stores/providers';
import { DEBUG_PATIENT } from 'src/stores/queries/patientDebug';
import {
  LOAD_PATIENTS,
  LOAD_PATIENT_LIMITED,
  LOAD_PATIENT_OVERVIEW,
} from 'src/stores/queries/patients';

let initialSnapshot;

const LATEST_TREATMENT_AGREEMENT_DOCUMENT_VERSION = '3';

type RootStore = {
  auth: Instance<typeof Auth>;
  events: Instance<typeof Events>;
  providers: Instance<typeof Providers>;
};

const PatientsList = createListModel('PatientsList', Patient, LOAD_PATIENTS);

export type PatientsInstance = Instance<typeof Patients>;
const Patients = types
  .model('PatientChart', {
    list: types.optional(PatientsList, { orderBy: 'lastName' }),
    calendar: types.optional(Calendar, {}),
    patient: types.maybeNull(Patient),
    eventInstances: types.maybeNull(types.array(Event)),
    debug: types.frozen(),
  })
  .actions(self => ({
    load: flow(function* load(id) {
      const patientChanged = !self.patient || self.patient.id !== id;
      const results = yield getEnv(self).apolloClient.query({
        query: LOAD_PATIENT_LIMITED,
        variables: { id },
      });
      self.patient = results.data.patient;

      if (patientChanged) {
        self.calendar.userId = self.patient?.id ?? '';
      }
    }),
    unload() {
      if (self.patient) {
        self.patient = null;
      }
    },
    loadPatientLimited: flow(function* loadPatientLimited(id) {
      const results = yield getEnv(self).apolloClient.query({
        query: LOAD_PATIENT_LIMITED,
        variables: { id },
      });
      self.patient = results.data.patient;
    }),
  }))
  .actions(self => ({
    reload() {
      if (self.patient) {
        self.load(self.patient.id);
      }
    },
  }))
  .actions(self => ({
    afterAttach() {
      addMiddleware(getRoot<RootStore>(self).events, (call, next) => {
        if (call.name === 'eventChanged' && call.context.event) {
          const event = call.context.event as EventInstance;
          // If the patient we have loaded is not the same as the patient
          // attending the event that was just changed, either load the
          // new attendee or unload if there isn't one.
          if (self.patient !== event.patientAttendee) {
            if (event.patientAttendee) {
              self.load(event.patientAttendee.id);
            } else {
              self.unload();
            }
          } else {
            // Otherwise, something has changed for the current patient
            self.reload();
          }
        }
        next(call);
      });
    },
    afterCreate() {
      // Store the default state of the store so it can be reset.
      initialSnapshot = getSnapshot(self);
    },
    loadPatientOverview: flow(function* loadPatientOverview(id) {
      const results = yield getEnv(self).apolloClient.query({
        query: LOAD_PATIENT_OVERVIEW,
        variables: { id },
      });
      self.patient = results.data.patient;
      self.calendar.userId = self.patient?.id ?? '';
    }),
    loadPatientDebug: flow(function* loadPatientDebug(id) {
      const { data } = yield getEnv(self).apolloClient.query({
        query: DEBUG_PATIENT,
        variables: { q: id },
      });
      self.debug = data.patientDebug;
      return data;
    }),
  }))
  .actions(self => ({
    loadPatientTab: flow(function* loadPatientTab(id, tab, queryParams) {
      switch (tab) {
        case 'calendar': {
          yield self.loadPatientOverview(id);
          const { date: dateString, view } = queryParams;
          const date = dateString ? parseISO(dateString) : new Date();
          self.calendar.loadDateAndView(date, view);
          break;
        }
        case 'debug':
          yield self.loadPatientDebug(id);
          break;
        case 'overview':
        default:
          yield self.loadPatientLimited(id);
          break;
      }
    }),
    reset() {
      const { currentView } = self.calendar;
      applySnapshot(self, initialSnapshot);
      self.calendar.currentView = currentView;
    },
    createPatient() {
      self.patient = Patient.create();
    },
    savePatient: flow(function* savePatient(patient) {
      if (patient.id) {
        const result = yield getEnv(self).crudService.update('Patient', patient, self.patient);
        yield self.loadPatientOverview(patient.id);
        return result;
      } else {
        const result = yield getEnv(self).crudService.create('Patient', patient);
        return result;
      }
    }),
    saveChartDocument: flow(function* saveChartDocument(newValues, chartDocument) {
      if (chartDocument.id) {
        yield getEnv(self).crudService.update('ChartDocument', newValues, chartDocument);
      } else {
        yield getEnv(self).crudService.create('ChartDocument', {
          ...newValues,
          patient: self.patient?.id,
        });
      }
      self.reload();
    }),
    deleteChartDocument: flow(function* deleteDocument(id) {
      yield getEnv(self).crudService.remove('ChartDocument', id);
      self.reload();
    }),
    messageChartDocumentToPatient(id) {
      return getEnv(self).apolloClient.mutate({
        mutation: MESSAGE_CHART_DOCUMENT_TO_PATIENT,
        variables: { id },
      });
    },
  }))
  .actions(self => ({
    requestTreatmentAgreement: flow(function* requestTreatmentAgreement() {
      yield self.savePatient({
        ...(self.patient && getSnapshot(self.patient)),
        treatmentAgreement: {
          documentVersion: LATEST_TREATMENT_AGREEMENT_DOCUMENT_VERSION,
        },
      });
    }),
  }));

export default Patients;
