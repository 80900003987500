import omit from 'lodash/omit';
import { reaction } from 'mobx';
import { createRouterState, RouterStore } from 'mobx-state-router';
import { types, getEnv } from 'mobx-state-tree';
import type { Instance } from 'mobx-state-tree';

import routes, { generateRouteUrl } from 'src/boot/routes';
import ProviderDataService from 'src/data/providers/ProviderDataService';
import logger from 'src/shared/util/logger';
import Auth from 'src/stores/auth';
import Claims from 'src/stores/claims';
import Events from 'src/stores/events';
import { UPLOAD_FILE } from 'src/stores/mutations/files';
import Patients from 'src/stores/patients';
import Pebbles from 'src/stores/pebbles';
import Providers from 'src/stores/providers';
import { SEARCH_MEDICATIONS, SEARCH_PAYORS, SEARCH_PROBLEMS } from 'src/stores/queries/datasets';
import { LOOKUP_PATIENTS, SEARCH_USERS } from 'src/stores/queries/userSearch';
import type { UserSearchResult } from 'src/stores/queries/userSearch';

const notFound = createRouterState('notFound');

// Reference to a promise we can resolve to abort an ongoing upload
let abortUpload;
const rootStore = types
  .model('RootStore', {
    providers: types.optional(Providers, {}),
    patients: types.optional(Patients, {}),
    events: types.optional(Events, {}),
    auth: types.optional(Auth, {}),
    flashMessage: types.maybeNull(types.string),
    flashDuration: types.maybeNull(types.integer),
    showFlashMessage: types.optional(types.boolean, false),
    claims: types.optional(Claims, {}),
    pebbles: types.optional(Pebbles, {}),

    isEditingByName: types.optional(types.map(types.boolean), {}),
  })
  .volatile(self => {
    const routerStore = new RouterStore(routes, notFound, { rootStore: self });

    return {
      routerStore,

      // Retrieve the route that would have been called without actually navigating
      // to it in the routerStore
      generateRouteUrl,
      async searchUsers(q: string): Promise<UserSearchResult[]> {
        if (q) {
          const {
            data: { userSearch: users },
          } = await getEnv(self).apolloClient.query({
            query: SEARCH_USERS,
            variables: { q },
          });
          return users;
        } else {
          return [];
        }
      },

      async getPatientById(id) {
        return getEnv(self).crudService.getOne('Patient', id, ['id', 'firstName', 'lastName']);
      },

      /**
       * Queries the API for patients
       *
       * @deprecated Use PatientDataService#search.
       */
      async searchPatients(q: string): Promise<UserSearchResult[]> {
        const {
          data: { patientLookup: items },
        } = await getEnv(self).apolloClient.query({
          query: LOOKUP_PATIENTS,
          variables: { q },
        });

        return items;
      },

      async getProviderById(id) {
        return getEnv(self).crudService.getOne('Provider', id, ['id', 'firstName', 'lastName']);
      },

      /**
       * Queries the API for providers
       *
       * @deprecated Use ProviderDataService#search.
       */
      async searchProviders(q) {
        const providerDataService = new ProviderDataService(getEnv(self).apolloClient);
        return providerDataService.search(q);
      },

      async searchMedications(q) {
        if (q && q.length >= 3) {
          const {
            data: { medications },
          } = await getEnv(self).apolloClient.query({
            query: SEARCH_MEDICATIONS,
            variables: { q },
          });
          return medications.map(item => omit(item, '__typename'));
        } else {
          return [];
        }
      },

      async searchMedicalConditions(q) {
        if (q && q.length >= 3) {
          const {
            data: { problems },
          } = await getEnv(self).apolloClient.query({
            query: SEARCH_PROBLEMS,
            variables: { q },
          });
          return problems.map(item => omit(item, '__typename'));
        } else {
          return [];
        }
      },
      async searchPayors(q: string, keys?: string[]) {
        if ((q && q.length >= 1) || (keys && keys.length >= 1)) {
          const {
            data: { payors },
          } = await getEnv(self).apolloClient.query({
            query: SEARCH_PAYORS,
            variables: { q, keys },
          });
          return payors.map(item => omit(item, '__typename'));
        } else {
          return [];
        }
      },
      async cancelUpload() {
        if (abortUpload) {
          abortUpload();
          abortUpload = null;
        }
      },

      async uploadFile(file, onUploadProgress) {
        const response = await getEnv(self).apolloClient.mutate({
          mutation: UPLOAD_FILE,
          variables: { data: file },
          errorPolicy: 'all',
          // This somewhat convoluted setup ultimately passes the callback through to the apollo
          // link context, where's it then available for the link to pass to the underlying
          // http lib.
          context: {
            fetchOptions: {
              useUpload: true,
              onUploadProgress,
              onAbortPromise: new Promise(resolve => {
                // We'll resolve this promise if we want to abort this upload
                abortUpload = resolve;
              }),
            },
          },
        });

        if (response.errors) {
          logger.error(
            `Could not upload file – ${response.errors
              .map(error => error.message || error.code)
              .join('; ')}`,
          );
          return null;
        }

        return response.data.createFile;
      },
    };
  })
  .views(self => ({
    get isEditing() {
      return Array.from(self.isEditingByName.values()).some(isEditingSection => !!isEditingSection);
    },
  }))
  .actions(self => ({
    // Watch for user logging in or out so we can setup any other stores appropriately.
    afterCreate() {
      reaction(
        () => self.auth.user,
        user => {
          if (user) {
            self._handleLogIn();
          }
        },
      );

      // Setup initial state
      if (self.auth.user) {
        self._handleLogIn();
      }
    },
    _handleLogIn() {
      if (!self.auth.user) {
        // Should be unreachable in this "after successful login" hook, but TS doesn't know that
        return;
      }
      self.pebbles.domain.getMyPebbleBadgeCount();
    },
    flash(message, duration = 5000) {
      self.flashMessage = message;
      self.flashDuration = duration;
      self.showFlashMessage = true;
    },
    clearFlashMessage() {
      // Use a separate flag instead of just setting the message to null so that the snackbar
      // component can animate out without first collapsing because it sees an empty value.
      self.showFlashMessage = false;
    },
    setEditing(name, value) {
      self.isEditingByName.set(name, value);
    },
    clearEditing() {
      self.isEditingByName.clear();
    },
  }));

export default rootStore;

export type RootStore = Instance<typeof rootStore>;
